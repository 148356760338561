import { ComponentProps } from 'react'

export const DiscordLogo = (props: ComponentProps<'svg'>) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path id="Vector" d="M19.2701 5.53C17.9401 4.91 16.5001 4.46 15.0001 4.2C14.987 4.19958 14.9739 4.20204 14.9618 4.20723C14.9497 4.21241 14.9389 4.22018 14.9301 4.23C14.7501 4.56 14.5401 4.99 14.4001 5.32C12.8091 5.08 11.1911 5.08 9.60012 5.32C9.46012 4.98 9.25012 4.56 9.06012 4.23C9.05012 4.21 9.02012 4.2 8.99012 4.2C7.49012 4.46 6.06012 4.91 4.72012 5.53C4.71012 5.53 4.70012 5.54 4.69012 5.55C1.97012 9.62 1.22012 13.58 1.59012 17.5C1.59012 17.52 1.60012 17.54 1.62012 17.55C3.42012 18.87 5.15012 19.67 6.86012 20.2C6.89012 20.21 6.92012 20.2 6.93012 20.18C7.33012 19.63 7.69012 19.05 8.00012 18.44C8.02012 18.4 8.00012 18.36 7.96012 18.35C7.39012 18.13 6.85012 17.87 6.32012 17.57C6.28012 17.55 6.28012 17.49 6.31012 17.46C6.42012 17.38 6.53012 17.29 6.64012 17.21C6.66012 17.19 6.69012 17.19 6.71012 17.2C10.1501 18.77 13.8601 18.77 17.2601 17.2C17.2801 17.19 17.3101 17.19 17.3301 17.21C17.4401 17.3 17.5501 17.38 17.6601 17.47C17.7001 17.5 17.7001 17.56 17.6501 17.58C17.1301 17.89 16.5801 18.14 16.0101 18.36C15.9701 18.37 15.9601 18.42 15.9701 18.45C16.2901 19.06 16.6501 19.64 17.0401 20.19C17.0701 20.2 17.1001 20.21 17.1301 20.2C18.8501 19.67 20.5801 18.87 22.3801 17.55C22.4001 17.54 22.4101 17.52 22.4101 17.5C22.8501 12.97 21.6801 9.04 19.3101 5.55C19.3001 5.54 19.2901 5.53 19.2701 5.53ZM8.52012 15.11C7.49012 15.11 6.63012 14.16 6.63012 12.99C6.63012 11.82 7.47012 10.87 8.52012 10.87C9.58012 10.87 10.4201 11.83 10.4101 12.99C10.4101 14.16 9.57012 15.11 8.52012 15.11ZM15.4901 15.11C14.4601 15.11 13.6001 14.16 13.6001 12.99C13.6001 11.82 14.4401 10.87 15.4901 10.87C16.5501 10.87 17.3901 11.83 17.3801 12.99C17.3801 14.16 16.5501 15.11 15.4901 15.11Z" />
  </svg>
)

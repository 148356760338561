import {ComponentProps} from 'react'

export const LinkedInLogo = (props: ComponentProps<'svg'>) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="ri:linkedin-fill">
      <path id="Vector" d="M6.93994 5.20001C6.93968 5.73044 6.72871 6.23904 6.35345 6.61393C5.97819 6.98881 5.46937 7.19927 4.93894 7.19901C4.40851 7.19874 3.89991 6.98777 3.52502 6.61251C3.15014 6.23725 2.93968 5.72844 2.93994 5.19801C2.94021 4.66757 3.15117 4.15897 3.52644 3.78408C3.9017 3.4092 4.41051 3.19874 4.94094 3.19901C5.47137 3.19927 5.97998 3.41024 6.35486 3.7855C6.72975 4.16076 6.94021 4.66957 6.93994 5.20001ZM6.99994 8.68001H2.99994V21.2H6.99994V8.68001ZM13.3199 8.68001H9.33994V21.2H13.2799V14.63C13.2799 10.97 18.0499 10.63 18.0499 14.63V21.2H21.9999V13.27C21.9999 7.10001 14.9399 7.33001 13.2799 10.36L13.3199 8.68001Z"/>
    </g>
  </svg>
)

import {ComponentProps} from 'react'

export const GithubLogo = (props: ComponentProps<'svg'>) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="mdi:github">
      <path id="Vector" d="M12 2.20001C10.6868 2.20001 9.38642 2.45867 8.17317 2.96122C6.95991 3.46376 5.85752 4.20036 4.92893 5.12894C3.05357 7.00431 2 9.54785 2 12.2C2 16.62 4.87 20.37 8.84 21.7C9.34 21.78 9.5 21.47 9.5 21.2V19.51C6.73 20.11 6.14 18.17 6.14 18.17C5.68 17.01 5.03 16.7 5.03 16.7C4.12 16.08 5.1 16.1 5.1 16.1C6.1 16.17 6.63 17.13 6.63 17.13C7.5 18.65 8.97 18.2 9.54 17.96C9.63 17.31 9.89 16.87 10.17 16.62C7.95 16.37 5.62 15.51 5.62 11.7C5.62 10.59 6 9.70001 6.65 8.99001C6.55 8.74001 6.2 7.70001 6.75 6.35001C6.75 6.35001 7.59 6.08001 9.5 7.37001C10.29 7.15001 11.15 7.04001 12 7.04001C12.85 7.04001 13.71 7.15001 14.5 7.37001C16.41 6.08001 17.25 6.35001 17.25 6.35001C17.8 7.70001 17.45 8.74001 17.35 8.99001C18 9.70001 18.38 10.59 18.38 11.7C18.38 15.52 16.04 16.36 13.81 16.61C14.17 16.92 14.5 17.53 14.5 18.46V21.2C14.5 21.47 14.66 21.79 15.17 21.7C19.14 20.36 22 16.62 22 12.2C22 10.8868 21.7413 9.58643 21.2388 8.37318C20.7362 7.15992 19.9997 6.05753 19.0711 5.12894C18.1425 4.20036 17.0401 3.46376 15.8268 2.96122C14.6136 2.45867 13.3132 2.20001 12 2.20001Z" />
    </g>
  </svg>
)
